import { createAction } from 'redux-act';
import { urls } from '../constants';
import { API } from '../api';
import { FILE } from '../constants';

export const createDraftIncidentReport = createAction(
    'Create draft incident report'
);

export const updateIncidentReportField = createAction(
    'Update incident report field'
);
export const updateMultiChoiceQuestionAnswer = createAction(
    'Update multi-choice question answer'
);
export const updateTextQuestionAnswer = createAction(
    'Update text question answer'
);

export const startSubmitDraft = createAction('Start submit draft');
export const removeDraft = createAction('Remove draft');
export const failSubmitDraft = createAction('Fail submit draft');
export const submitDraft = ({
    incidentReport,
    onComplete,
    onFail,
}) => dispatch => {
    dispatch(startSubmitDraft);
    API.post(urls.incidents, incidentReport)
        .then(({ data }) => {
            onComplete(data);
            dispatch(removeDraft(incidentReport));
        })
        .catch(e => {
            onFail(e);
            dispatch(failSubmitDraft);
        });
};

export const startSubmitAttachment = createAction('Start submit attachment');
export const completeSubmitAttachment = createAction(
    'Complete submit attachment'
);
export const failSubmitAttachment = createAction('Fail submit attachment');
export const removeAttachment = createAction(
    'Remove attachment from incident report'
);
export const submitAttachment = ({
    reportId,
    attachment,
    onComplete,
    onFail,
}) => dispatch => {
    dispatch(startSubmitAttachment);
    let config = {};
    let data = attachment;

    if (attachment.attachment_type === FILE) {
        config.headers = {
            'Content-Type': 'multipart/form-data',
        };
        data = new FormData();
        data.append('file', attachment.file);
        data.append('region', attachment.region);
        data.append('attachment_type', attachment.attachment_type);
    }

    API.post('/api/attachments/', data, config)
        .then(({ data }) => {
            dispatch(
                completeSubmitAttachment({ reportId: reportId, data: data })
            );
            onComplete(data);
        })
        .catch(e => {
            let message = 'Failed to add attachment';
            if (e.response?.data?.file?.[0]) {
                message = e.response?.data?.file?.[0];
            }
            dispatch(failSubmitAttachment);
            onFail(message);
        });
};

export const makeDraftForSavedReport = createAction(
    'Make draft for saved report'
);

export const startSubmitIncidentUpdate = createAction(
    'Start submit incident update'
);
export const completeSubmitIncidentUpdate = createAction(
    'Complete submit incident update'
);
export const failSubmitIncidentUpdate = createAction(
    'Fail submit incident update'
);
export const submitIncidentUpdate = ({
    id,
    incidentReport,
    onComplete,
    onFail,
}) => dispatch => {
    dispatch(startSubmitIncidentUpdate);
    API.patch(urls.incident(id), incidentReport)
        .then(({ data }) => {
            dispatch(completeSubmitIncidentUpdate(data));
            onComplete(data);
        })
        .catch(e => {
            dispatch(failSubmitIncidentUpdate);
            onFail(e);
        });
};
