import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useJsApiLoader } from '@react-google-maps/api';

import './App.css';
import { AppThemeProvider, useIsFullsize } from '@azavea/ifes-ever-ui';
import { Flex } from '@chakra-ui/react';
import Loading from './components/Loading';
import ErrorBoundary from './components/ErrorBoundary';
import OfflineHeader from './components/OfflineHeader';
import { googleMapsApiKey, GOOGLE_MAPS_REGION_CODES } from './constants';
import { loadGoogleMapScript } from './actions/loadGoogleMapScript';
import { useRegion } from './api';

const Home = lazy(() => import('./components/Home'));
const Tip = lazy(() => import('./components/Tip'));
const Help = lazy(() => import('./components/Help'));
const Dashboard = lazy(() => import('./components/Dashboard'));
const Incidents = lazy(() => import('./components/Incidents'));
const IncidentReport = lazy(() => import('./components/IncidentReport'));
const FullsizeTopbarWrapper = lazy(() =>
    import('./components/FullsizeTopbarWrapper')
);
const Membership = lazy(() => import('./components/Membership'));

const googleMapsLibraries = ['places'];

const LoadGoogleMapScripts = ({ region }) => {
    const dispatch = useDispatch();

    // Note that useJsApiLoader does NOT like to be called twice with different parameters. Multiple
    // calls with the same parameters work fine, but different parameters will raise an exception.
    // See https://github.com/JustFly1984/react-google-maps-api/issues/3221
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: googleMapsApiKey,
        libraries: googleMapsLibraries,
        region: GOOGLE_MAPS_REGION_CODES[region.hostname],
    });

    useEffect(() => {
        dispatch(
            loadGoogleMapScript({
                isLoaded,
                loadError,
            })
        );
    }, [isLoaded, loadError, dispatch]);
    return <></>;
};

function App() {
    const isFullsize = useIsFullsize();
    const { data: region } = useRegion();

    return (
        <AppThemeProvider>
            <Router>
                <Suspense fallback={<Loading />}>
                    <ErrorBoundary>
                        <Flex direction='column' flex='1' height={'100vh'}>
                            <OfflineHeader />
                            {!!region && (
                                <LoadGoogleMapScripts region={region} />
                            )}
                            {isFullsize && <FullsizeTopbarWrapper />}
                            <Switch>
                                <Route exact component={Home} path='/' />
                                <Route exact component={Tip} path='/tip' />
                                <Route exact component={Help} path='/help' />
                                <Route
                                    exact
                                    component={Dashboard}
                                    path='/dashboard'
                                />
                                <Route
                                    exact
                                    component={Incidents}
                                    path='/incidents'
                                />
                                <Route
                                    exact
                                    component={IncidentReport}
                                    path={[
                                        '/incidents/:id',
                                        '/incidents/:id/:group',
                                    ]}
                                />
                                <Route
                                    exact
                                    component={Membership}
                                    path='/membership'
                                />
                            </Switch>
                        </Flex>
                    </ErrorBoundary>
                </Suspense>
            </Router>
        </AppThemeProvider>
    );
}

export default App;
