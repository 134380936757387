export const urls = Object.freeze({
    incidents: '/api/incidents/',
    incident: id => `/api/incidents/${id}/`,
    approveIncidentReport: id => `/api/incidents/${id}/approve/`,
    rejectIncidentReport: id => `/api/incidents/${id}/reject/`,
    requestDeleteIncidentReport: id => `/api/incidents/${id}/request_delete/`,
    region: '/api/region/',
    // The django.contrib.auth view that takes a provider path argument must not
    // end with a trailing slash
    login: '/login/auth0',
    logout: '/logout/',
    user: '/api/user/',
    filteredIncidents: search => `/api/incidents/${search}`,
    incidentReport: id => `/api/incidents/${id}/`,
    downloadAttachment: id => `/api/download-attachment/${id}`,
});

export const languages = Object.freeze({
    en: {
        nativeName: 'English',
        switchLabel: 'Switch language to English',
        abbreviation: 'EN',
    },
    ar: {
        nativeName: 'العربية',
        switchLabel: 'تبديل اللغة إلى العربية',
        abbreviation: 'AR',
    },
});

// Question types
export const DATE = 'DATE';
export const TIME = 'TIME';
export const TEXT = 'TEXT';
export const MULTI_CHOICE = 'MULTI_CHOICE';
export const SINGLE_CHOICE = 'SINGLE_CHOICE';

// Answer types
export const INTEGER = 'INTEGER';
export const BOOLEAN = 'BOOLEAN';

// Attachment types
export const PHOTO = 'PHOTO';
export const FILE = 'FILE';
export const URL = 'URL';

// Should match settings in django settings.py
const MAX_FILE_SIZE_MB = 20;
const BYTES_PER_MB = 1048576;
export const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * BYTES_PER_MB; // in bytes
export const ACCEPTED_FILE_EXTENSIONS = [
    'aif',
    'aiff',
    'avi',
    'gif',
    'heic',
    'jpeg',
    'jpg',
    'm4a',
    'mov',
    'mp3',
    'mp4',
    'pdf',
    'png',
    'wav',
    'wmv',
];

// Default zoom when viewing a single location
export const DEFAULT_ZOOM = 11;
// Map EVER hostnames to Google region codes, which are mostly, but not always, the same as
// ccTLDs. https://developers.google.com/maps/coverage
export const GOOGLE_MAPS_REGION_CODES = {
    iraq: 'IQ',
    libya: 'LY',
};

export const googleStaticMapsApiBase =
    'https://maps.googleapis.com/maps/api/staticmap';

// TODO: Swap for IFES key #99
export const mapboxToken =
    'pk.eyJ1IjoiYXphdmVhIiwiYSI6ImNrdTMxM3lzZjJiNm8ybnFod214Mmk2enUifQ.KkhPy3yg_alb-tdy3oelVw';
export const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
// TODO: Public a Monocrome Light style in the IFES account and swap it in here
export const mapboxStyleUrl =
    'mapbox://styles/azavea/ckx7nlgje1nlj14patnzuuj1n';
