import { createReducer } from 'redux-act';
import produce from 'immer';

import { loadGoogleMapScript } from '../actions/loadGoogleMapScript';

const initialState = Object.freeze({});

const GoogleMapScriptReducer = createReducer(
    {
        [loadGoogleMapScript]: (state, { isLoaded, loadError }) =>
            produce(state, () => ({
                isLoaded,
                loadError,
            })),
    },
    initialState
);

export default GoogleMapScriptReducer;
