import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './i18n';
import configureStore from './store';

const { store, persistor } = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading='Loading' persistor={persistor}>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

serviceWorkerRegistration.register({
    onSuccess: () => {
        alert('The application is ready for offline use');
    },
    onUpdate: () => {
        alert(
            'An update is available and will be used when all ' +
                'tabs for this page are closed'
        );
    },
});
