import i18next from '../i18n';
import { View } from '@react-pdf/renderer';

import { TEXT, MULTI_CHOICE, BOOLEAN } from '../constants';

export const makeDraftIncidentReportId = () =>
    `DRAFT${new Date()
        .toISOString()
        .replaceAll('Z', '')
        .replaceAll('-', '')
        .replaceAll('.', '')
        .replaceAll(':', '')}`;

export const election_type = 'election_type';
export const start_date = 'start_date';
export const end_date = 'end_date';
export const incident_time = 'incident_time';
export const location_name = 'location_name';
export const location_coordinates = 'location_coordinates';
export const online_form = 'online_form';
export const physical_location_form = 'physical_location_form';

export const makeDraftIncidentReport = ({ region, id, form }) => {
    return {
        region,
        id,
        form,
        [election_type]: [],
        [start_date]: null,
        [end_date]: null,
        [incident_time]: null,
        [location_name]: null,
        [location_coordinates]: null,
        answers: [],
        attachments: [],
        dateType: null,
    };
};

export const getIncidentReport = (reports, id) => {
    return reports.find(r => r.id.toString() === id.toString());
};

export const isDraft = x => {
    const id = x?.id ? x.id.toString() : x.toString();
    return id.startsWith('DRAFT');
};

export const getForm = (report, region) => {
    if (!region || !report) return null;

    const formKeys = Object.keys(region).filter(k => k.endsWith('_form'));
    const form = formKeys
        .map(k => ({ key: k, ...region[k] }))
        .filter(f => f.id === report.form)?.[0];

    return form || null;
};

export const checkedOptions = (question, answers) => {
    const value = answers.find(a => a.question === question.id)?.value;
    if (!value) {
        return null;
    }
    // Handle the case where a MULTI_CHOICE may have previously been a
    // SINGLE_CHOICE and the saved value is a plain string rather than an
    // object
    const normalizedValue =
        typeof value === 'string' || value instanceof String
            ? { [value]: true }
            : value;
    const items = Object.keys(normalizedValue).map(k => {
        const a = question.answers.find(a => a.key === k);
        if (a.answer_type === BOOLEAN) {
            return <li key={a.key}>{i18next.t(a.en_label)}</li>;
        }
        return (
            <li key={a.key}>{`${i18next.t(a.en_label)}: ${
                normalizedValue[k]
            }`}</li>
        );
    });
    return <ul>{items}</ul>;
};

export const checkedOptionsPdf = (question, answers) => {
    const value = answers.find(a => a.question === question.id)?.value;
    if (!value) {
        return null;
    }
    // Handle the case where a MULTI_CHOICE may have previously been a
    // SINGLE_CHOICE and the saved value is a plain string rather than an
    // object
    const normalizedValue =
        typeof value === 'string' || value instanceof String
            ? { [value]: true }
            : value;
    const items = Object.keys(normalizedValue).map(k => {
        const a = question.answers.find(a => a.key === k);
        if (a.answer_type === BOOLEAN) {
            return i18next.t(a.en_label);
        }
        return `${i18next.t(a.en_label)}: ${normalizedValue[k]}`;
    });
    return <View>{items.join(', ')}</View>;
};

export const getQuestionContents = (question, answers, pdf = false) => {
    switch (question.question_type) {
        case TEXT:
            return answers.find(a => a.question === question.id)?.value;
        case MULTI_CHOICE:
            return pdf
                ? checkedOptionsPdf(question, answers)
                : checkedOptions(question, answers);
        default:
            return answers.find(a => a.question === question.id)?.value;
    }
};
