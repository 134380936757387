import { Spinner, Flex } from '@chakra-ui/react';

export default function Loading() {
    return (
        <Flex
            flex='1'
            alignItems='center'
            justifyContent='center'
            height='100vh'
        >
            <Spinner color='cyan.500' size='xl' thickness='4px' speed='0.65s' />
        </Flex>
    );
}
