import { createReducer } from 'redux-act';
import produce from 'immer';
import {
    createDraftIncidentReport,
    updateIncidentReportField,
    updateMultiChoiceQuestionAnswer,
    updateTextQuestionAnswer,
    removeDraft,
    completeSubmitAttachment,
    removeAttachment,
    makeDraftForSavedReport,
} from '../actions/draftIncidentReports';
import {
    makeDraftIncidentReport,
    getIncidentReport,
    election_type,
} from '../helpers/incidentHelper';
import { BOOLEAN, FILE, URL } from '../constants';

const initialState = Object.freeze([]);

const DraftIncidentReportsReducer = createReducer(
    {
        [createDraftIncidentReport]: (state, payload) =>
            produce(state, draft => {
                draft.unshift(makeDraftIncidentReport(payload));
            }),
        [updateIncidentReportField]: (state, payload) =>
            produce(state, draft => {
                const report = getIncidentReport(draft, payload.reportId);
                if (payload.fieldName === election_type) {
                    if (!report.election_type) {
                        report.election_type = [];
                    }
                    if (report.election_type.indexOf(payload.value) === -1) {
                        report.election_type.push(payload.value);
                    } else {
                        report.election_type = report.election_type.filter(
                            t => t !== payload.value
                        );
                    }
                } else {
                    report[payload.fieldName] = payload.value;
                }
                return draft;
            }),
        [updateTextQuestionAnswer]: (
            state,
            { reportId, questionId, answerValue }
        ) =>
            produce(state, draft => {
                const report = getIncidentReport(draft, reportId);
                let answer = report.answers.find(
                    a => a.question === questionId
                );

                if (answer) {
                    answer.value = answerValue;
                } else {
                    report.answers.push({
                        question: questionId,
                        value: answerValue,
                    });
                }
            }),
        [updateMultiChoiceQuestionAnswer]: (
            state,
            {
                reportId,
                questionId,
                answerValue,
                eventData,
                multiChoiceAnswerType,
            }
        ) =>
            produce(state, draft => {
                const report = getIncidentReport(draft, reportId);
                let answer = report.answers.find(
                    a => a.question === questionId
                );

                // The question was previously answered, and a new answer
                // is being added or a secondary value is being updated
                if (answer && eventData.checked) {
                    // Handle the case where a MULTI_CHOICE may have previously been a
                    // SINGLE_CHOICE and the saved value is a plain string rather than an
                    // object
                    const normalizedValue =
                        answer?.value &&
                        (typeof answer.value === 'string' ||
                            answer.value instanceof String)
                            ? { [answer.value]: true }
                            : answer?.value;

                    if (multiChoiceAnswerType === BOOLEAN) {
                        normalizedValue[answerValue] = eventData.checked;
                    } else {
                        normalizedValue[answerValue] = eventData.value;
                    }
                    // Copy the normalized value back so that immer can make the
                    // state update
                    answer.value = normalizedValue;

                    // The question was previously answered, but the current
                    // option is no longer checked
                } else if (answer && !eventData.checked) {
                    delete answer.value[answerValue];
                    // The question was not previously answered, but now an
                    // answer is being provided
                } else if (!answer && eventData.checked) {
                    const value =
                        multiChoiceAnswerType === BOOLEAN
                            ? true
                            : eventData.value;

                    report.answers.push({
                        question: questionId,
                        value: {
                            [answerValue]: value,
                        },
                    });
                }
            }),
        [removeDraft]: (state, { id }) => {
            // Remove draft
            const index = state.findIndex(d => d.id === id);
            if (index < 0) {
                return state;
            }
            return produce(state, draft => {
                draft.splice(index, 1);
                return draft;
            });
        },
        [makeDraftForSavedReport]: (state, payload) => {
            return produce(state, draft => {
                const index = state.findIndex(d => d.id === payload.id);
                if (index >= 0) {
                    draft.splice(index, 1);
                }
                draft.unshift(payload);
                return draft;
            });
        },
        [completeSubmitAttachment]: (state, payload) => {
            return produce(state, draft => {
                const report = getIncidentReport(draft, payload.reportId);

                if (payload.data.attachment_type === URL) {
                    report.attachments.push({
                        id: payload.data.id,
                        url: payload.data.url,
                        attachment_type: URL,
                    });
                } else if (payload.data.attachment_type === FILE) {
                    report.attachments.push({
                        id: payload.data.id,
                        file: payload.data.file,
                        attachment_type: FILE,
                    });
                }
            });
        },
        [removeAttachment]: (state, { reportId, attachmentId }) => {
            return produce(state, draft => {
                const report = getIncidentReport(draft, reportId);

                if (report.attachments.length >= 1) {
                    const itemIndex = report.attachments.findIndex(
                        a => a.id === attachmentId
                    );
                    if (itemIndex !== -1) {
                        report.attachments.splice(itemIndex, 1);
                    }
                }
            });
        },
    },
    initialState
);

export default DraftIncidentReportsReducer;
