import axios from 'axios';
import useSWR from 'swr';
import { urls } from './constants';
import i18n from './i18n';

// Used to make authenticated HTTP requests to Django
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.xsrfCookieName = 'csrftoken';
export const API = axios.create({
    headers: {
        credentials: 'same-origin',
    },
});

/**
 * Given an optional dictionary mapping status codes to descriptive messages,
 * returns an async function that will, given a url, return its parsed data, if
 * there are no errors. If there are any API errors, it will throw. If the API
 * response is not JSON, it will throw. The thrown error will have a
 * `subheading` field, to be used in the UI messaging.
 *
 * @param {object} statusMessages
 */
const makeGetWithErrorHandling = (statusMessages = {}) => async url => {
    const { data, headers } = await API.get(url).catch(async ({ response }) => {
        // In case of error, add relevant data to the error object
        // so the right message can be shown to the user.
        const err = new Error();
        const status = response?.status;
        err.subheading =
            status && statusMessages[status]
                ? statusMessages[status]
                : i18n.t('Something went wrong');

        err.status = status;
        err.info = await response?.data;
        throw err;
    });

    // If response is not JSON, this is likely because it was made to a bad
    // URL, but instead of a server error, the response is the full
    // application, delivered by Django SPA. Report this as an error.
    if (headers?.['content-type'] !== 'application/json') {
        const err = new Error();
        err.subheading = i18n.t('Malformed URL');
        err.info = { url };
        throw err;
    }

    return data;
};

export const fetcher = makeGetWithErrorHandling();

export const logout = () => (window.location.href = urls.logout);

export const useUser = () => useSWR(urls.user, fetcher);

// TODO: TRANSLATION
export const useIncidents = () =>
    useSWR(
        urls.incidents,
        makeGetWithErrorHandling({
            403: i18n.t('You do not have the required permissions'),
        })
    );

export const useFilteredIncidents = search =>
    useSWR(urls.filteredIncidents(search), fetcher);

export const useRegion = () =>
    useSWR(
        urls.region,
        makeGetWithErrorHandling({
            403: i18n.t('You do not have the required permissions'),
        })
    );

export const useIncidentReport = id =>
    useSWR(
        urls.incidentReport(id),
        makeGetWithErrorHandling({
            403: 'That incident report is not available',
        })
    );

export const approveIncidentreport = async id =>
    await API.post(urls.approveIncidentReport(id));

export const rejectIncidentreport = async id =>
    await API.post(urls.rejectIncidentReport(id));

export const requestDeleteIncidentReport = async id =>
    await API.post(urls.requestDeleteIncidentReport(id));
