import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react';
import { useNetworkState } from 'react-use';
import { useTranslation } from 'react-i18next';

export default function OfflineHeader() {
    const networkState = useNetworkState();
    const { t } = useTranslation();

    return (
        <>
            {!networkState.online && (
                <Alert
                    status='warning'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    textAlign='center'
                    height='200px'
                    overflow='display'
                >
                    <AlertIcon />
                    <AlertTitle>
                        {t('No network connection detected. Working offline.')}
                    </AlertTitle>
                    <AlertDescription>
                        {t(
                            'Reports can still be created, but can not be submitted ' +
                                'until a network connection is reestablished.'
                        )}
                    </AlertDescription>
                </Alert>
            )}
        </>
    );
}
