import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
    // Reference: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // Reference: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    .use(initReactI18next)
    // Referece: https://www.i18next.com/overview/configuration-options
    .init({
        // TODO: Make the debug setting conditional on the environment in which the
        // code is running
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        // Our project has a single common namespace named "translations"
        ns: ['translations'],
        defaultNS: 'translations',

        keySeparator: false, // we use our string content as keys

        react: {
            // Setting this ensures that we don't render content until the translation is loaded
            wait: true,
        },
    });

i18n.on('languageChanged', language => (document.dir = i18n.dir(language)));

export default i18n;
