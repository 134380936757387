import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { VStack } from '@chakra-ui/react';

const styles = {
    hash: {
        background: '#e6e6e6',
        padding: '20px',
        wordWrap: 'break-word',
        margin: '20px',
        fontSize: '12px',
        letterSpacing: '1px',
        color: '#7d7d7d',
    },
};

class ErrorBoundary extends Component {
    state = {};

    componentDidCatch(error) {
        this.setState({ error });
    }

    // To decode this later, just run "atob(hashedStringHere)"
    encodeErrorMessage = error =>
        btoa(`${error.message} ::::::: ${error.stack}`);

    render() {
        const { t } = this.props;

        if (this.state.error) {
            return (
                <VStack px={4} py={4} spacing={4} alignItems='stretch'>
                    <h2>{t('Something went wrong')}</h2>
                    <p>
                        {t('Try refreshing the page')}{' '}
                        {t(
                            'If the problem persists contact support and send along the text below'
                        )}
                    </p>
                    <div style={styles.hash} className='notranslate'>
                        {this.encodeErrorMessage(this.state.error)}
                    </div>
                </VStack>
            );
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
};

export default withTranslation()(ErrorBoundary);
